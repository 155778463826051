.Input {
  font-size: calc(14rem / 16);
  color: var(--primary-900);
  > p {
    &:empty {
      display: none;
    }
    margin: 0 6px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px 4px;
    flex-wrap: wrap;
    svg {
      flex-shrink: 0;
    }
  }
  input {
    height: 44px;
  }
  textarea {
    min-height: 82px;
    resize: none;
  }
  input,
  textarea {
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--form-100);
    padding: 8px;
    transition: border-color 0.5s;
    font-size: 1rem;
    &::placeholder {
      color: var(--primary-500);
    }
    &:hover {
      border-color: var(--form-100);
    }
    &:focus-visible {
      outline: none;
      border-color: var(--form-300);
    }
  }
  &[aria-invalid='true'] {
    input,
    textarea {
      border-color: var(--error-500);
    }
  }
}
