.Checkbox {
  cursor: pointer;
  display: inline-grid;
  grid-template-columns: auto 1fr;
  width: fit-content;
  gap: 8px;
  border-radius: 2px;
  font-size: calc(14rem / 16);
  .icon {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid var(--neutral-500);
    display: grid;
    place-items: center;
    transition: border-color 0.5s;
    overflow: hidden;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: -6px;
      top: -6px;
      right: -6px;
      bottom: -6px;
      background-color: rgba(21, 166, 105, 0.5);
      border-radius: 50%;
      transition: transform 0.5s var(--easing), opacity 0.5s var(--easing);
      opacity: 0;
      transform: scale(0);
    }
    input {
      position: absolute;
      opacity: 0;
    }
    svg {
      stroke-dasharray: 16;
      stroke-dashoffset: 16;
      transition: stroke-dashoffset 0.5s var(--easing);
    }
  }
  p {
    margin-top: 5px;
    > span[data-error] {
      margin-left: auto;
      margin-top: 4px;
    }
  }
  &:has(input:checked) {
    .icon {
      border-color: rgba(21, 166, 105, 0.5);
      &::before {
        transform: scale(1);
        opacity: 1;
      }
      svg {
        stroke-dashoffset: 0;
      }
    }
  }
  &:has(input:focus-visible) {
    .icon {
      border-radius: 8px;
      outline: 2px solid var(--secondary-500, #376578);
      outline-offset: 3px;
    }
  }
  &:hover {
    .icon {
      border-color: var(--neutral-700);
    }
  }
  &[aria-invalid='true'] {
    .icon {
      border-color: var(--error-500);
    }
  }
}
